<template>
    <div>
        <div class="page_title">
            {{$i18n.t('ota.text1')}}
        </div>

        <div class="ota_main">
            <el-tabs v-model="activeName" class="main" @tab-click="handleClick">
                <el-tab-pane :label="$i18n.t('ota.text2')" name="1">
                </el-tab-pane>
                <el-tab-pane :label="$i18n.t('ota.text3')" name="2">
                </el-tab-pane>
            </el-tabs>

            <!-- 头部产品详情 start -->
            <div class="product_msg" style="margin-top: 4px;" v-if="!!productData.pid">
                <div>
                    <el-image v-show="activeName === '1'" :src="productData.productImageUrl" class="product_img">
                        <div slot="error">
                            <div class="image_slot">
                                {{$i18n.t('debug.text3')}}
                            </div>
                        </div>
                    </el-image>
                    <div class="product_msg_list" :style="{marginLeft: activeName === '1' ? '0' : '20px'}">
                        <div class="product_msg_top">
                            <div class="product_name">{{ productData.productName }}</div>

                            <el-popover
                                placement="bottom"
                                width="500"
                                popper-class="product_popover"
                                v-model="isShowProductPopover"
                                trigger="click">
                                <div class="product_main">
                                    <el-input clearable @hide="productName = ''" @input="searchProduct" v-model="productName" size="small" :placeholder="$i18n.t('ota.text4')"></el-input>
                                    <div class="product_list" v-loading="loadingProduct" v-if="productList.length > 0">
                                        <div @click="switchProduct(item)" :class="productData.pid === item.pid ? 'product_list_item_action' : 'product_list_item'" v-for="(item, index) in productList" :key="index">
                                            <span>{{ item.name }}</span>
                                            <span style="color: rgba(77, 79, 83, 0.5);font-size: 12px;margin-top: 6px;">PID：{{ item.pid }}</span>
                                        </div>
                                    </div>
                                    <el-empty :description="$i18n.t('ota.text5')" v-loading="loadingProduct" :image-size="90" v-else></el-empty>
                                </div>
                                <el-button slot="reference"  class="product_edit" size="mini" plain type="primary">
                                    {{$i18n.t('debug.text4')}}
                                    <i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                            </el-popover>

                        </div>
                        <div class="product_msg_bottom">
                            <div class="product_msg_item">PID：{{ productData.pid }}</div>
                            <div class="product_msg_item" v-if="activeName === '1'">{{$i18n.t('debug.text5')}}{{ productData.categoryName }}</div>
                        </div>
                    </div>
                </div>
<!--                <div class="header_right">-->
<!--                    <div class="header_right_top">{{totalVersionsNumber}}</div>-->
<!--                    <div class="header_right_bottom">版本总数</div>-->
<!--                </div>-->
            </div>

            <div class="product_msg" style="margin-top: 4px;" v-else>
                <el-empty style="margin-left: 10px;" :description="$i18n.t('debug.text6')" :image-size="40"></el-empty>
            </div>
            <!-- 头部产品详情 end -->

            <div class="table_card">
                <div class="card_heard">
                    <el-form :inline="true" size="small">
                        <el-form-item :label="$i18n.t('ota.text6')">
                            <el-date-picker
                                style="width: 370px;"
                                v-model="searchForm.time"
                                type="datetimerange"
                                :range-separator="$i18n.t('empower.text11')"
                                :start-placeholder="$i18n.t('empower.text12')"
                                :end-placeholder="$i18n.t('empower.text13')"
                                @change="searchByTime"
                                value-format="yyyy-MM-dd HH:mm:ss">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="firmwareMode" :placeholder="$i18n.t('ota.text7')" @change="selectFirmwareMode" style="width: 130px;">
                                <el-option v-for="(item, index) in firmwareModelList" :key="index" :label="item.label" :value="item.label"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-input :placeholder="$i18n.t('ota.text8')" @blur="getFirmwareListPager(page)"
                                      style="width: 160px;" v-model="searchForm.keywords" size="small" class="input-with-select">

                                <!--                                <el-button slot="append" @click="search" icon="el-icon-search"></el-button>-->
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small" round @click="resetFrom">{{$i18n.t('ota.text106')}}</el-button>
                        </el-form-item>
                    </el-form>
                    <div>
                        <el-button type="primary" size="small" round @click="addExternalProducts" v-show="activeName === '2'">{{$i18n.t('ota.text107')}}</el-button>
                        <el-button type="primary" size="small" round @click="addOTA" :disabled="!!!productData.pid">{{$i18n.t('ota.text9')}}</el-button>
                    </div>
                </div>

                <el-table
                    ref="otaTable"
                    class="ota_table"
                    :data="otaData.list"
                    size="mini"
                    style="width: 100%;margin-top: 16px;"
                    @filter-change="filterHandler"
                    :height="tableHeight"
                    :header-cell-style="{background:'#eef1f6',color:'rgba(0, 0, 0 ,.85)', paddingLeft: '24px'}"
                    :cell-style="{color:'rgba(0, 0, 0 ,.65)', padding: '6px 24px'}"
                    v-loading="loading">
                    <el-table-column
                        width="150"
                        prop="version"
                        :label="$i18n.t('ota.text10')">
                    </el-table-column>
                    <el-table-column
                        width="150"
                        :label="$i18n.t('ota.text11')">
                        <template slot-scope="s">
                            {{$config.getMsgItemUtil(triggerTypeList, s.row.triggerType, 'value', 'text')}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="150"
                        :filter-multiple="false"
                        :filters="$message.firmwareTypeList"
                        column-key="firmwareType"
                        prop="firmwareType"
                        :label="$i18n.t('ota.text12')">
                        <template slot-scope="s">
                            {{$config.getMsgItemUtil($message.firmwareTypeList, s.row.firmwareType, 'value', 'text')}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        min-width="200"
                        :label="$i18n.t('ota.text13')">
                        <template slot-scope="s">
                            <span class="notes">{{s.row.versionDescribe}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="210"
                        prop="publishTime"
                        :label="$i18n.t('ota.text14')">
                    </el-table-column>
                    <el-table-column
                        width="250"
                        :filter-multiple="false"
                        :filters="$message.publishStatusList"
                        column-key="status"
                        :label="$i18n.t('ota.text15')">
                        <template slot-scope="s">
                            <div style="margin: 10px 0;">
                                <span v-if="s.row.publishStatus === 0" style="padding: 5px 19px;color: rgb(215, 215, 215);border: 1px solid rgb(215, 215, 215);border-radius: 8px;">{{$i18n.t('ota.text16')}}</span>
                                <span v-if="s.row.publishStatus === 1" style="padding: 5px 19px;color: rgb(245, 154, 35);border: 1px solid rgb(245, 154, 35);border-radius: 8px;">{{$i18n.t('ota.text17')}}</span>
                                <span v-if="s.row.publishStatus === 2" style="padding: 5px 19px;color: rgb(112, 182, 3);border: 1px solid rgb(112, 182, 3);border-radius: 8px;">{{$i18n.t('ota.text18')}}</span>
                                <span v-if="s.row.publishStatus === 3" style="padding: 5px 19px;color: rgb(0, 79, 255);border: 1px solid rgb(0, 79, 255);border-radius: 8px;">{{$i18n.t('ota.text19')}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        width="210"
                        prop="planPublishTime"
                        :label="$i18n.t('ota.text20')">
                    </el-table-column>

                    <el-table-column
                        fixed="right"
                        width="300"
                        :label="$i18n.t('ota.text21')">
                        <template slot-scope="s">
                            <el-button type="text" size="small" @click="downFirmware(s.row)">{{$i18n.t('ota.text22')}}</el-button>
                            <el-button type="text" size="small" @click="toReleaseMsg(s.row)" v-if="!(s.row.publishStatus === 3 && s.row.publishTimeType === 1)">{{$i18n.t('ota.text23')}}</el-button>
                            <el-button type="text" size="small" @click="toFirmwareMsg(s.row)">{{$i18n.t('ota.text24')}}</el-button>
                            <el-button type="text" size="small" @click="modifyFirmware(s.row)">{{$i18n.t('ota.text25')}}</el-button>
                            <el-button type="text" size="small" @click="banFirmware(s.row)" v-if="s.row.publishStatus !== 0">{{$i18n.t('ota.text26')}}</el-button>
                            <el-button type="text" size="small" @click="delFirmware(s.row)" style="color:#d45c34;" v-if="s.row.publishStatus === 0">{{$i18n.t('ota.text27')}}</el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :page-size="10"
                    :current-page="page"
                    layout="prev, pager, next"
                    style="margin-top: 10px;text-align: right;padding-bottom: 10px;"
                    :total="parseInt(otaData.totalRecords)">
                </el-pagination>
            </div>

        </div>

        <add-o-t-a-drawer @getMsg="getProductMsg"></add-o-t-a-drawer>
    </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import AddOTADrawer from "@/views/maintain/components/addOTADrawer";

export default {

    name: "ota",
    components: {AddOTADrawer},

    computed: {
        ...mapState('product', ['productMsg'])
    },

    watch: {
        //产品详情
        productMsg: {
            handler(newVal) {
                console.log('productMsg', newVal)
                this.productData = {
                    productName: newVal.name,
                    pid: newVal.pid,
                    productImageUrl: newVal.icon,
                    categoryName: newVal.categoryName ? (this.language === 'zh' ? JSON.parse(newVal.categoryName).zh : JSON.parse(newVal.categoryName).en) : ""
                }
                this.getFirmwareModelList();
            },
            deep: true
        }
    },

    data() {

        return {

            activeName: '1',

            productName: "",

            productList: [],

            currentProduct: {},

            loading: false,

            loadingProduct: false,

            isShowProductPopover: false,

            triggerTypeList: [
                {
                    value: 0,
                    text: this.$i18n.t('ota.text28')
                },
                {
                    value: 1,
                    text: this.$i18n.t('ota.text29')
                },
                {
                    value: 2,
                    text: this.$i18n.t('ota.text30')
                },
                {
                    value: 3,
                    text: this.$i18n.t('ota.text31')
                }
            ],

            //产品详情
            productData: {
                //产品名称
                productName: "",
                pid: "",
                //产品拟物图
                productImageUrl: "",
                //品类名称
                categoryName: ""
            },

            searchForm: {

                keywords: "",

                time: ['', ''],

                firmwareType: null,

                publishStatus: null

            },

            firmwareMode: '',

            tableHeight: 50,

            firmwareModelList: [],

            otaData: {
                list: [],
                totalRecords: 0
            },

            page: 1,

            language: localStorage.getItem('language'),

            searchProductTimeout: null
        }

    },

    mounted() {
        setTimeout(() => {
            this.setHeight();
        }, 100);
        window.addEventListener('resize', this.setHeight);
        this.firmwareMode = (this.$route.query.firmwareMode && this.$route.query.firmwareMode !== '') ? this.$route.query.firmwareMode : ''
        this.searchForm.keywords = (this.$route.query.version && this.$route.query.version !== '') ? this.$route.query.version : ''
        this.searchForm.time = (this.$route.query.time && this.$route.query.time !== '') ? JSON.parse(this.$route.query.time) : ['', '']
        this.activeName = (this.$route.query.type && this.$route.query.type !== '') ? this.$route.query.type : '1';
        this.getProductMsg();
    },

    methods: {
        ...mapActions('product', ['queryProduct', 'getProductDetail']),
        ...mapActions('maintain', ['queryFirmwareModelList', 'queryPager', 'addProductalias', 'banOTA', 'delOTA', 'queryProductAndAliasSimpleList']),

        handleClick() {
            this.firmwareMode =  ''
            this.searchForm.keywords = ''
            this.searchForm.time = ['', '']
            this.$config.changeURLArg('type', this.activeName);
            this.$config.changeURLArg('id', '');
            this.$config.changeURLArg('firmwareMode', '');
            this.$config.changeURLArg('version', '');
            this.$config.changeURLArg('time', JSON.stringify(['', '']));
            this.getProductMsg();
        },

        async switchProduct(item) {
            if (item.pid === this.currentProduct.pid) return;
            this.$config.changeURLArg('id', item.code);
            this.$config.changeURLArg('firmwareMode', '');
            this.$config.changeURLArg('version', '');
            this.$config.changeURLArg('time', JSON.stringify(['', '']));
            this.firmwareMode =  ''
            this.searchForm.keywords = ''
            this.searchForm.time = ['', '']
            this.isShowProductPopover = false;
            this.currentProduct = item;
            if(this.activeName === '1') {
                await this.getProductDetail({
                    productCode: this.currentProduct.code,
                    isRefresh: true
                });
            } else {
                this.productData = {
                    productName: item.name,
                    pid: item.pid
                }
                await this.getFirmwareModelList();
            }
        },

        selectFirmwareMode(event) {
            this.firmwareMode = event;
            this.$config.changeURLArg('firmwareMode', this.firmwareMode);
            this.getFirmwareListPager(1);
        },

        /**
         * 获取产品列表
         */
        getProductMsg() {
            this.queryProductAndAliasSimpleList({
                productName: this.productName,
                productType: Number(this.activeName),
            }).then(async res => {
                if (res.code === '0') {
                    this.productList = this.activeName === '1' ?
                        (res.result.productList ? res.result.productList : []) :
                        (res.result.productAliasList ? res.result.productAliasList : []);
                    console.log('getProductMsg', this.productList, this.$route.query.id, !!this.$route.query.id, this.$route.query.id !== '')
                    if (!this.productList && this.productList.length === 0) return;
                    if (!!this.$route.query.id && this.$route.query.id !== '') {
                        this.$config.deepCopy(this.productList).forEach(item => {
                            if (this.$route.query.id === item.code) {
                                this.currentProduct = item;
                            }
                        })
                    } else {
                        if (this.productList.length === 0) {
                            this.currentProduct = [];
                            this.$store.commit('product/SET_PRODUCT_MSG', this.currentProduct);
                            return
                        }
                        this.currentProduct = this.$config.deepCopy(this.productList)[0];
                        this.$config.changeURLArg('id', this.productList[0].code);
                        this.$config.changeURLArg('type', this.activeName);
                    }
                    if (this.activeName === '1') {
                        if (!this.currentProduct.code) return;
                        await this.getProductDetail({
                            productCode: this.currentProduct.code,
                            isRefresh: true
                        });
                    } else {
                        this.$store.commit('product/SET_PRODUCT_MSG', this.currentProduct);
                    }
                }
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            })
        },



        searchProduct(event) {
            this.productName = event;
            this.loadingProduct = true;
            if (this.searchProductTimeout) clearTimeout(this.searchProductTimeout);
            this.searchProductTimeout = setTimeout(() => {
                this.queryProductAndAliasSimpleList({
                    productName: this.productName,
                    productType: Number(this.activeName),
                }).then(res => {
                    if (res.code === '0') {
                        this.productList = this.activeName === '1' ? res.result.productList : res.result.productAliasList;
                        console.log(this.currentProduct)
                    }
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                }).finally(() => this.loadingProduct = false)
            }, event === '' ? 500 : 1000)
        },

        searchByTime(event) {
            this.searchForm.time = event ? event : ['', ''];
            this.$config.changeURLArg('time', JSON.stringify(this.searchForm.time));
            this.getFirmwareListPager(1)
        },

        async getFirmwareModelList() {
            this.loading = true;
            await this.queryFirmwareModelList({
                firmwareModel: '',
                pid: this.productData.pid
            }).then(res => {
                let list = [];
                if (res.result) {
                    res.result.forEach((item, index) => {
                        list.push({
                            label: item.firmwareModel,
                            value: item.firmwareModel
                        })
                    })
                    this.firmwareModelList = list;
                    if (list.length > 0) {
                        this.firmwareMode = this.firmwareMode ? this.firmwareMode : list[0].value;
                        this.$config.changeURLArg('firmwareMode', this.firmwareMode);
                        this.getFirmwareListPager();
                    }
                } else {
                    this.firmwareMode = ""
                    this.$config.changeURLArg('firmwareMode', '');
                    this.firmwareModelList = [];
                    this.otaData.totalRecords = 0;
                    this.otaData.list = [];
                }
            }).finally(() => {
                this.loading = false;
            })
        },

        getFirmwareListPager(page) {
            if (page) this.page = page;
            if (!this.searchForm.time) this.searchForm.time = ['', '']
            this.$config.changeURLArg('version', this.searchForm.keywords);
            this.loading = true;
            this.queryPager({
                beginCreateTime: this.searchForm.time.length > 0 ? this.searchForm.time[0] : '',
                endCreateTime: this.searchForm.time.length > 0 ? this.searchForm.time[1] : '',
                firmwareModel: this.firmwareMode,
                currPage: this.page,
                pageSize: 10,
                version: this.searchForm.keywords,
                pid: this.productData.pid,
                publishStatus: this.searchForm.publishStatus,
                firmwareType: this.searchForm.firmwareType
            }).then(res => {
                this.otaData.totalRecords = res.result.totalRecords;
                this.otaData.list = res.result.list;
            }, err => {
                console.log(err);
                this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
            }).finally(() => {
                this.loading = false;
            })
        },

        banFirmware(row) {
            this.$dialog.showConfirm({
                content: this.$i18n.t('ota.text32'),
                title: this.$i18n.t('ota.text33'),
                btn1: this.$i18n.t('ota.text34'),
                btn2: this.$i18n.t('ota.text35'),
                icon: 2
            }, () => {
                this.banOTA({otaFileCode: row.code}).then(res => {
                    this.$dialog.showMessage(this.$i18n.t('ota.text36'), this.$config.TOAST_SUCCESS);
                    this.getFirmwareListPager();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, () => {

            })
        },

        delFirmware(row) {
            this.$dialog.showConfirm({
                content: this.$i18n.t('ota.text37'),
                title: this.$i18n.t('ota.text38'),
                btn1: this.$i18n.t('ota.text39'),
                btn2: this.$i18n.t('ota.text35'),
                icon: 2
            }, () => {
                this.delOTA({otaFileCode: row.code}).then(res => {
                    this.$dialog.showMessage(this.$i18n.t('ota.text40'), this.$config.TOAST_SUCCESS);
                    this.getFirmwareListPager();
                }, err => {
                    console.log(err);
                    this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                })
            }, () => {

            })
        },

        downFirmware(row) {
            window.location.href = row.srcDownUrl ? row.srcDownUrl :  row.srcFileUrl
        },

        toFirmwareMsg(row) {
            this.$router.push({
                path: "/firmwareMsg",
                query: {
                    code: row.code
                }
            })
        },

        toReleaseMsg(row) {
            this.$router.push({
                path: "/releaseMsg",
                query: {
                    code: row.code
                }
            })
        },

        modifyFirmware(row) {
            row.productName = this.productData.productName
            console.log('modifyFirmware', row);
            this.$store.commit('maintain/SET_ADD_OTA_DRAWER_TYPE', 1);
            this.$store.commit('maintain/SET_FIRMWARE_MSG', {
                otaFileCode: row.code,
                firmwareModel: row.firmwareModel,
                srcFilePath: row.srcFilePath,
                srcFileUrl: row.srcFileUrl,
                productType: row.productType,
                triggerType: (row.triggerType || row.triggerType === 0) ? row.triggerType : '',
                downloadType: (row.downloadType || row.downloadType === 0) ? row.downloadType : '',
                installType: (row.installType || row.installType === 0) ? row.installType : '',
                reboot: row.reboot ? "1" : "2",
                versionDescribe: row.versionDescribe ? row.versionDescribe : '',
                pid: row.pid,
                wipeData: row.wipeData,
                wipeMedia: row.wipeMedia
            });
            this.$store.commit('maintain/SET_ADD_OTA_DRAWER_VISIBLE', true);
        },

        /**
         * 切换产品回调
         * @param command
         */
        async handleCommand(command) {
            this.$config.changeURLArg('id', command.code);
            this.currentProduct = command;
            await this.getProductDetail({
                productCode: command.code,
                isRefresh: true
            });
            await this.getFirmwareModelList();
        },

        filterHandler(event) {
            if (event.firmwareType) this.searchForm.firmwareType = event.firmwareType.length > 0 ? event.firmwareType[0] : null;
            if (event.status) this.searchForm.publishStatus = event.status.length > 0 ? event.status[0] : null;
            this.getFirmwareListPager(1)
        },

        addOTA() {
            this.$store.commit('maintain/SET_FIRMWARE_MSG', {
                productName: this.productData.productName,
                pid: this.productData.pid,
                firmwareModel: this.firmwareMode,
                productType: Number(this.activeName),
            });
            this.$store.commit('maintain/SET_ADD_OTA_DRAWER_TYPE', 0);
            this.$store.commit('maintain/SET_ADD_OTA_DRAWER_VISIBLE', true);
        },

        addExternalProducts() {
            this.$prompt(this.$i18n.t('mainIndex.text19'), this.$i18n.t('ota.text107'), {
                confirmButtonText: this.$i18n.t('mainIndex.text74'),
                cancelButtonText: this.$i18n.t('mainIndex.text75'),
                inputPattern: /^.{1,20}$/,
                inputErrorMessage: this.$i18n.t('ota.text108')
            }).then(({ value }) => {
                    this.addProductalias({
                        name: value
                    }).then(res => {
                        this.$dialog.showMessage(this.$i18n.t('ota.text94'), this.$config.TOAST_SUCCESS);
                        this.getProductMsg();
                    }, err => {
                        console.log(err);
                        this.$dialog.showMessage(err.resultMsg, this.$config.TOAST_ERROR);
                    })
            });
        },

        resetFrom() {
            this.searchForm.keywords = "";
            this.searchForm.time = ['', ''];
            this.$config.changeURLArg('version', "");
            this.$config.changeURLArg('time', JSON.stringify(this.searchForm.time));
            this.getFirmwareListPager(1)
        },

        /**
         * 设备表格高度
         */
        setHeight() {
            this.tableHeight = (window.innerHeight - 360);
        },

        handleCurrentChange(page) {
            this.getFirmwareListPager(page)
        },

        destroyed() {
            window.removeEventListener('resize', this.setHeight);
        }
    }
}
</script>

<style scoped>


.main {
    width: 100%;
    /*height: calc(100% - 150px);*/
    border: none;
}


/deep/ .main .el-tabs__item {
    padding: 0 20px !important;
    height: 20px;
    line-height: 20px;
    margin: 10px 0;
}

/deep/ .main .el-tabs__item {
    border-right: 1px solid #E1E1E1;
}

/deep/ .main .el-tabs__item:last-child {
    border-right: none;
}

.table_card {
    position: relative;
    margin: 0 24px 20px 24px;
    text-align: left;
}

.card_heard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


/deep/ .el-table__header-wrapper {
    border-radius: 8px 8px 0 0;
}


.ota_main {
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    position: relative;
    margin: 64px 24px 20px 24px;
    animation: fadeInRight; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

.product_msg {
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0 0 4px 0 #BCBCBC;
    border-radius: 8px;
    position: relative;
    margin: 24px 24px 20px 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.image_slot {
    background-color: #F7F7F7;
    width: 50px;
    height: 50px;
    font-size: 12px;
    text-align: center;
    padding: 13px 5px 0 5px;
    color: #c0c4cc;
}

.product_img {
    width: 60px;
    height: 60px;
    float: left;
    background-color: #f7f7f7;
    border-radius: 8px;
    margin: 10px 20px;
}

.product_msg_list {
    height: 60px;
    margin: 10px 0;
    float: left;
}

.product_msg_top {
    height: 22px;
    padding: 7px 0;
    display: flex;
}

.product_msg_bottom {
    height: 22px;
}

.product_name {
    font-weight: bold;
    font-size: 16px;
    color: black;
    float: left;
    line-height: 22px;
}

.product_msg_item {
    float: left;
    font-size: 10px;
    color: #595959;
    font-weight: 400;
    margin-left: 40px;
}

.product_msg_item:first-child {
    margin-left: 0;
}

/deep/ .el-dropdown-menu {
    position: relative!important;
    left: 0!important;
    top: 22px!important;
    z-index: 6!important;
}

/deep/ .el-dropdown-menu__item {
    white-space: nowrap;
}

/deep/ .el-dropdown-menu__item:first-child {
    margin-top: 4px!important;
}



.company_list_item_activate {
    background-color: #e8e9ed;
    color: #494d72;
}

.el-breadcrumb {
    line-height: normal;
}

/deep/ .el-breadcrumb__inner, /deep/ .el-breadcrumb__separator {
    font-weight: bold !important;
    font-size: 16px !important;
    color: black !important;
}

.product_msg .el-empty {
    padding: 0px!important;
}

.product_msg /deep/ .el-empty__description {
    margin-top: 10px!important;
}

.product_msg /deep/ .el-empty__description p {
    font-size: 12px!important;
}

.product_edit {
    float: left;
    margin-left: 21px;
    height: 22px;
    padding: 3px;
}

.header_right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
}

.header_right_top {
    font-size: 24px;
    color: rgb(24, 19, 14);
}

.header_right_bottom {
    font-size: 13px;
    color: rgb(24, 19, 14);
    margin-top: 6px;
}

/deep/ .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
    margin-bottom: 0!important;
}

.ota_table {
    margin-top: 10px;
}

.product_main {
    display: flex;
    flex-direction: column;
}

.product_list {
    max-height: 300px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 10px;
}

.product_list_item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    margin-top: 4px;
}

.product_list_item:hover {
    background-color: rgb(232, 244, 255);
    color: rgb(70, 166, 255);
}

.product_list_item_action {
    display: flex;
    flex-direction: column;
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    background-color: #e8e9ed;
    color: #494d72;
    margin-top: 4px;
}

.notes {
    width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
